/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Amsterdam"), React.createElement("p", null, "Pour se loger, Amsterdam s’articule autour de plusieurs quartiers :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/nl/amsterdam/oud-centrum.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Binnenstad"), " : la vieille ville d’Amsterdam concentre les monuments."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/nl/amsterdam/redlightdistrict.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Quartier rouge"), ", près de la gare : ses vitrines, ses coffee shops et bars animés."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/landmark/nl/waterloo-square.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Waterlooplein et Plantage"), ", à l’est : quartiers résidentiels verdoyants près du zoo et du jardin botanique."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/nl/amsterdam/grachtengordel.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Quartier des canaux"), ", autour du centre : atmosphère très romantique."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/nl/amsterdam/jordaan.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Jordaan"), ", à l’ouest : quartier tranquille avec ses cafés et boutiques."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/landmark/nl/leidse-square.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Leidseplein"), ", au sud-ouest : quartier avec de nombreux clubs et salles de concert."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/nl/amsterdam/museumquarter.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Quartier des musées"), ", au sud de Leidseplein : très chic, avec ses musées célèbres et Vondelpark."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/nl/amsterdam/depijp.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "De Pijp"), ", au sud-est : quartier populaire connu pour son marché et ses restaurants."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/nl/amsterdam/amsterdam-noord.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Noord"), ", au nord de la gare : anciennes friches industrielles réhabilitées autour du EYE Filmmuseum."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
